import request from "@/utils/request.js"

export function listUserArticleFavorite(params) {
    return request.request({
        url: '/api/user/article/favorite/list',
        method: 'get',
        params:params
    })
}


export function saveUserArticleFavorite(data) {
    return request.request({
        url: '/api/user/article/favorite/save',
        method: 'post',
        data
    })
}


export function listUserArticlePowerByUser(params) {
    return request.request({
        url: '/api/user/article/power/list',
        method: 'get',
        params:params
    })
}


export function receiveOldUserWelfare() {
    return request.request({
        url: '/api/user/oldUserWelfare',
        method: 'post',
    })
}


export function isUserArticlePower(params) {
    return request.request({
        url: '/api/user/article/power/isHave',
        method: 'get',
        params:params
    })
}


export function saveArticlePower(data) {
    return request.request({
        url: '/api/user/article/power/save',
        method: 'post',
        data:data
    })
}


export function lisIntegralDetailsByUser(params) {
    return request.request({
        url: '/api/user/integral/details/list',
        method: 'GET',
        params
    })
}


export function isSignIn() {
    return request.request({
        url: '/api/user/isSignIn',
        method: 'GET',
    })
}


export function signIn() {
    return request.request({
        url: '/api/user/signIn',
        method: 'GET',
    })
}


export function getMyIntegral() {
    return request.request({
        url: '/api/user/myIntegral',
        method: 'GET',
    })
}


export function uvCount() {
    return request.request({
        url: '/api/user/count/uv',
        method: 'GET',
    })
}

export function register(data) {
    return request.request({
        url: '/api/user/register',
        method: 'post',
        data:data
    })
}

export function logout() {
    return request.request({
        url: '/api/user/logout',
        method: 'post',
    })
}

export function login(data) {
    return request.request({
        url: '/oauth/token',
        method: 'post',
        data:data
    })
}

export function againSendActivationEmail(data) {
    return request.request({
        url: '/api/user/againSendActivationEmail',
        method: 'post',
        data:data
    })
}

export function sendResetPasswordEmail(data) {
    return request.request({
        url: '/api/user/sendResetPasswordEmail',
        method: 'post',
        data:data
    })
}

export function resetPassword(data) {
    return request.request({
        url: '/api/user/password/reset',
        method: 'put',
        data:data
    })
}

export function activationEmail(data) {
    return request.request({
        url: '/api/user/activationEmail',
        method: 'post',
        data:data
    })
}

export function updateUser(data) {
    return request.request({
        url: '/api/user/update',
        method: 'put',
        data:data
    })
}

export function updatePassword(data) {
    return request.request({
        url: '/api/user/password/update',
        method: 'put',
        data:data
    })
}

export function getMeInfo(params) {
    return request.request({
        url: '/api/user/me',
        method: 'get',
        params:params
    })
}

export function saveSearchHistory(data) {
    return request.request({
        url: '/api/history/search',
        method: 'post',
        data:data
    })
}


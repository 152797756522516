import axios from 'axios'
import { Toast } from 'vant';

// create an axios inst ance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    // baseURL: "https://www.qjzx.top/",
    timeout: 30 * 1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        let token = localStorage.getItem("access_token");
        if (token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['Authorization'] = token;
        }
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 200) {
            if (res.code === 50 || res.code === 51) {
                localStorage.removeItem("access_token")
                sessionStorage.removeItem("userInfo")
            }
            Toast(res.msg);
        }
        return res
    },
    error => {
        // console.log(error)
        if (error.response) {
            let status = error.response.status;
            Toast('请求错误:' + error.response.status);
            console.log('error:', error) // for debug
        } else {
            Toast(error.message);
        }
        return Promise.reject(error)

    }
)

export default service

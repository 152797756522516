import request from '@/utils/request'

export function listCategory(params) {
  return request({
    url: '/api/category/list',
    method: 'get',
    params: params
  })
}``

export function treeCategory(params) {
  return request({
    url: '/api/category/tree',
    method: 'get',
    params: params
  })
}


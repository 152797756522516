import Vue from 'vue'
import { Toast } from 'vant';
import { Popup  } from 'vant';
import { Icon } from 'vant';
import { Tab } from 'vant';
import { Sidebar } from 'vant';
import { SidebarItem } from 'vant';
import { Tabs } from 'vant';
import { Steps,Step } from 'vant';
import { Swipe, SwipeItem } from "vant";
import { Pagination } from 'vant';
import { Dialog } from 'vant';

Vue.use(Dialog);
Vue.use(Pagination);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(Tab);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Tabs);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Swipe);
Vue.use(SwipeItem);

import Vue from 'vue'
import Vuex from 'vuex'
import * as userApi from "@/api/user"
import * as categoryApi from "@/api/category"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        theme: 'light',
        token: '',
        isLogin: false,
        userInfo: {},
        category: [],
    },
    getters: {},
    mutations: {
        updateTheme(state, theme) {
            state.theme = theme;
            window.document.documentElement.setAttribute('data-theme', theme);
        },
        updateCategory(state, category) {
            state.category = category;
        },
        updateIsLogin(state, isLogin) {
            state.isLogin = isLogin;
        },
        updateUserInfo(state, user) {
            state.userInfo = user;
        },
        setToken(state, token) {
            state.token = token;
        }
    },
    actions: {
        async getUserInfo(context) {
            let token = localStorage.getItem("access_token")
            if (token) {
                try {
                    let result = await userApi.getMeInfo();
                    sessionStorage.setItem("userInfo", JSON.stringify(result.data))
                    context.commit("updateUserInfo", result.data)
                    context.commit("updateIsLogin", true)
                    return result.data;
                } catch (e) {
                    context.commit("updateIsLogin", false)
                    return {code: 0}
                }
            }
        },
        async getCategory(context) {
            let category = JSON.parse(localStorage.getItem("category"));
            if (category) {
                context.commit("updateCategory", category.category);
                let result = await categoryApi.treeCategory();
                if (category.updateTime !== result.data.updateTime) {
                    context.commit("updateCategory", result.data.category)
                    localStorage.setItem("category", JSON.stringify(result.data))
                }
            } else {
                let result = await categoryApi.treeCategory();
                context.commit("updateCategory", result.data.category)
                localStorage.setItem("category", JSON.stringify(result.data))
            }
        }
    },
    modules: {}
})

<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name:'app',
  computed:{
    ...mapState(["theme"])
  },
  mounted() {
    let theme = localStorage.getItem("theme");
    if (theme) {
      this.updateTheme(theme);
    }else {
      this.updateTheme(this.theme);
    }
  },
  methods:{
    ...mapMutations(["updateTheme"])

  }
}
</script>

<style>
</style>

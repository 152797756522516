import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'vant/lib/index.css';
// 引入全部样式
import 'vant/lib/index.less';
// 全局引入按需引入UI库 vant
import '@/plugins/vant'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

/**
 * vue图片懒加载
 */
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("./assets/img/lazy.png"),// 错误图片
  loading: require("./assets/img/lazy.png"), // 加载中图片
  attempt: 1,
})

/**
 * vue图片预览
 */
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview, {
      // fullscreenEl: false, // 关闭全屏按钮
      // zoomEl: false, // 关闭放大按钮
      // tapToClose: false, // 点击关闭图片
      loadingIndicatorDelay: 10
    }
)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
